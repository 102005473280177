import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { CztWidgets, Widget } from '~/utils/views/widgets';
import isGridList from '~/components/organisms/gridList/isGridList';
import isTabsWidget from '../common/tabsWidget/isTabsWidget';
import isCarousel from '~/components/organisms/carousel/isCarousel';
import isGallery from '~/components/organisms/gallery/isGallery';
import isContentBlock from '~/components/organisms/contentBlock/isContentBlock';
import isIframeBlock from '~/components/organisms/iframeBlock/isIframeBlock';
import isMapWidget from '../mapWidget/isMapWidget';
import isVenueFinder from '../common/venueFinder/isVenueFinder';
import isSearchForm from '~/components/organisms/searchForm/isSearchForm';
import isTradeOffersList from '../common/tradeOffersList/isTradeOffersList';
import { isRepresentationMap } from '../common/representationMap/isRepresentationMap';
import isSafeTravelsList from '../safeTravels/isSafeTravelsList';
import isPanorama from '~/components/organisms/panorama/isPanorama';
import isNewsletterForm from '../common/newsletterForm/isNewsletterForm';
import isSlider from '~/components/organisms/slider/isSlider';
import isSmartGuide from '~/components/organisms/smartGuide/isSmartGuide';

interface WidgetRendererInterface {
  widgets: Widget[];
  isFirst?: boolean;
  side?: boolean;
}

@Component({
  components: {
    carousel: () => import('~/components/organisms/carousel/Carousel'),
    'content-block': () =>
      import('~/components/organisms/contentBlock/ContentBlock'),
    gallery: () => import('~/components/organisms/gallery/Gallery'),
    'gallery-grid': () => import('~/components/organisms/gallery/GalleryGrid'),
    'grid-list': () => import('~/components/organisms/gridList/GridList'),
    'iframe-block': () =>
      import('~/components/organisms/iframeBlock/IframeBlock'),
    'map-widget': () => import('../mapWidget/MapWidget'),
    'newsletter-form': () => import('../common/newsletterForm/NewsletterForm'),
    panorama: () => import('~/components/organisms/panorama/Panorama'),
    'representation-map': () =>
      import('../common/representationMap/RepresentationMap'),
    'safe-travels-list': () => import('../safeTravels/SafeTravelsList'),
    'search-form': () => import('~/components/organisms/searchForm/SearchForm'),
    slider: () => import('~/components/organisms/slider/Slider'),
    'smart-guide': () => import('~/components/organisms/smartGuide/SmartGuide'),
    'tabs-widget': () =>
      import('~/components/templates/common/tabsWidget/TabsWidget'),
    'trade-offers-list': () =>
      import('../common/tradeOffersList/TradeOffersList'),
    'venue-finder': () => import('../common/venueFinder/VenueFinder'),
  },
})
export default class WidgetRenderer
  extends VueComponent<WidgetRendererInterface>
  implements WidgetRendererInterface {
  @Prop({ required: true })
  public widgets!: Widget[];

  @Prop({ default: false, type: Boolean })
  public isFirst!: boolean;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  public render() {
    return (
      <div class='czt-rendered-widgets'>
        {this.widgets.map(this.getMappedComponent)}
      </div>
    );
  }

  protected getMappedComponent(widget: Widget): JSX.Element | null {
    if (isGridList(widget)) {
      return (
        <grid-list
          anchorId={widget.anchorId}
          className={widget.className}
          guid={widget.guid}
          image={widget.image}
          imageAspectRatio={widget.imageAspectRatio}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          link={widget.link}
          title={widget.title}
          maxItems={widget.maxItems}
          nextPageCount={widget.nextPageCount}
          pageSize={widget.pageSize}
          showFlags={widget.showFlags}
          taxonomies={widget.taxonomies}
          taxonomyFilter={widget.taxonomyFilter}
          taxonomyLists={widget.taxonomyLists}
          type={widget.type}
        />
      );
    } else if (isSlider(widget)) {
      return (
        <slider
          isFirst={this.isFirst}
          anchorId={widget.anchorId}
          className={widget.className}
          items={widget.items}
          delay={widget.delay}
          contentWidth={widget.contentWidth}
          ratio={widget.ratio}
        />
      );
    } else if (isCarousel(widget)) {
      return (
        <carousel
          isFirst={this.isFirst}
          anchorId={widget.anchorId}
          className={widget.className}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          title={widget.title}
        />
      );
    } else if (isGallery(widget)) {
      if (!widget.isGrid) {
        return (
          <gallery
            anchorId={widget.anchorId}
            isFirst={this.isFirst}
            className={widget.className}
            items={widget.items}
            ratio={widget.ratio}
            useContentWidth={widget.useContentWidth}
          />
        );
      }
      return (
        <gallery-grid
          anchorId={widget.anchorId}
          isFirst={this.isFirst}
          className={widget.className}
          items={widget.items}
          title={widget.title}
        />
      );
    } else if (isContentBlock(widget)) {
      return (
        <content-block
          anchorId={widget.anchorId}
          caption={widget.caption}
          className={widget.className}
          content={widget.content}
          image={widget.image}
          imageSource={widget.imageSource}
          imageUrl={widget.imageUrl}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isFirst={this.isFirst}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          position={widget.position}
          side={this.side}
          themeStyle={widget.themeStyle}
          title={widget.title}
        />
      );
    } else if (isSmartGuide(widget)) {
      return (
        <smart-guide
          anchorId={widget.anchorId}
          audioUrl={widget.audioUrl}
          className={widget.className}
          deepLinkUrl={widget.deepLinkUrl}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          side={this.side}
          title={widget.title}
        />
      );
    } else if (isTabsWidget(widget)) {
      return (
        <tabs-widget
          anchorId={widget.anchorId}
          className={widget.className}
          image={widget.image}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          isFirst={this.isFirst}
          side={this.side}
        />
      );
    } else if (isIframeBlock(widget)) {
      return (
        <iframe-block
          anchorId={widget.anchorId}
          className={widget.className}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          title={widget.title}
          url={widget.url}
          height={widget.height}
          side={this.side}
        />
      );
    } else if (isMapWidget(widget)) {
      return (
        <map-widget
          anchorId={widget.anchorId}
          className={CztWidgets.MAP}
          defaultStyle={widget.defaultStyle}
          id={widget.id}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          latitude={widget.latitude}
          longitude={widget.longitude}
          preselectedPoi={widget.preselectedPoi}
          title={widget.title}
          zoom={widget.zoom}
          detail={this.side}
        />
      );
    } else if (isVenueFinder(widget)) {
      return (
        <venue-finder
          anchorId={widget.anchorId}
          className={widget.className}
          guid={widget.guid}
          title={widget.title}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
        />
      );
    } else if (isSearchForm(widget)) {
      return (
        <v-container class='czt-spacer'>
          <h3 class='czt-search-form__widget-title mb-4'>
            {this.$t('app.search.widgetTitle')}
          </h3>
          <v-row justify='center' no-gutters>
            <v-col cols='12' lg='8'>
              <search-form className={widget.className} term={widget.term} />
            </v-col>
          </v-row>
        </v-container>
      );
    } else if (isTradeOffersList(widget)) {
      return (
        <trade-offers-list
          anchorId={widget.anchorId}
          className={widget.className}
          guid={widget.guid}
          filterOptions={widget.filterOptions}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
          link={widget.link}
          title={widget.title}
          maxItems={widget.maxItems}
          nextPageCount={widget.nextPageCount}
          pageSize={widget.pageSize}
        />
      );
    } else if (isRepresentationMap(widget)) {
      return (
        <representation-map
          anchorId={widget.anchorId}
          className={widget.className}
          continent={widget.continent}
          guid={widget.guid}
          title={widget.title}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          items={widget.items}
        />
      );
    } else if (isSafeTravelsList(widget)) {
      return (
        <safe-travels-list
          anchorId={widget.anchorId}
          className={widget.className}
          guid={widget.guid}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          title={widget.title}
        />
      );
    } else if (isPanorama(widget)) {
      return (
        <panorama
          anchorId={widget.anchorId}
          className={widget.className}
          caption={widget.caption}
          guid={widget.guid}
          image={widget.image}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
          rotate={widget.rotate}
          title={widget.title}
          imageSource={widget.imageSource}
        />
      );
    } else if (isNewsletterForm(widget)) {
      return (
        <newsletter-form
          anchorId={widget.anchorId}
          className={widget.className}
          guid={widget.guid}
          type={widget.type}
          isBottomSpacingCollapsed={widget.isBottomSpacingCollapsed}
          isTopSpacingCollapsed={widget.isTopSpacingCollapsed}
        />
      );
    }
    return null;
  }
}
