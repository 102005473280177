import { getPathWithLocale, getSimpleUrlFromPath } from '~/app/core/router';
import { defaultLocale } from '~/app/localization';
import {
  getCDNSrc,
  getSrcSet,
  getWebpSrc,
  setQuality,
} from '~/components/atoms/image/Image';

export enum ROBOTS {
  INDEX_FOLLOW = 'index,follow',
  INDEX_NOFOLLOW = 'index,nofollow',
  NOINDEX = 'noindex',
  NOINDEX_NOFOLLOW = 'none',
}

export enum TYPE {
  ARTICLE = 'article',
  WEBSITE = 'website',
}

export enum SchemaType {
  ARTICLE = 'Article',
  EVENT = 'Event',
}

export const defaultTitle = '#VisitCzechia';

export function createJsonLd({
  type = SchemaType.ARTICLE,
  title,
  image,
  created,
  modified,
  startDate,
  endDate,
  address,
  locationName,
  description,
}: {
  type?: SchemaType;
  title: string | null;
  image: string[];
  created?: string;
  modified?: string;
  startDate?: string;
  endDate?: string;
  address?: string;
  locationName?: string;
  description?: string;
}) {
  const schema: any = {
    '@context': 'https://schema.org',
    '@type': type,
    image,
  };

  if (type === SchemaType.EVENT && startDate && title?.trim() && address) {
    schema.name = title.trim();
    schema.startDate = startDate;
    schema.endDate = endDate;
    schema.location = {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        name: address,
      },
    };
    if (locationName?.trim()) {
      schema.location.name = locationName.trim();
    }
    if (description?.trim()) {
      schema.description = description.trim();
    }
  } else {
    schema['@type'] = SchemaType.ARTICLE;
    (schema.headline = title?.trim() || defaultTitle),
      (schema.author = [
        {
          '@type': 'Organization',
          name: 'CzechTourism',
          url: 'https://www.czechtourism.cz/',
        },
      ]);
    if (created) {
      schema.datePublished = created;
    }
    if (modified) {
      schema.dateModified = modified;
    }
  }

  return `<script type="application/ld+json">
  ${JSON.stringify(schema)}
  </script>`;
}

export default function({
  title,
  description,
  canonicalUrl,
  type = TYPE.ARTICLE,
  image,
  imagesToPreload = [],
  robots = ROBOTS.INDEX_FOLLOW,
  mutations = [],
  path,
}: {
  title: string | null;
  description: string;
  canonicalUrl: string;
  type?: TYPE;
  image?: string;
  imagesToPreload?: string[];
  robots?: ROBOTS;
  mutations?: string[];
  path?: string;
}): string {
  const titleParts = [defaultTitle];
  if (title) {
    titleParts.unshift(title);
  }
  let content = `
<meta name="robots" content="${robots}" />
${canonicalUrl ? `<link rel="canonical" href="${canonicalUrl}" />` : ''}
${canonicalUrl ? `<meta property="og:url" content="${canonicalUrl}" />` : ''}
<meta property="og:type" content="${type}">
<meta name="author" content="CzechTourism" />
<meta name="twitter:creator" content="CzechTourism" />
<meta name="twitter:title" content="${titleParts.join(' · ')}" />
<meta property="og:title" content="${titleParts.join(' · ')}" />
<meta name="description" content="${description}" />
<meta name="twitter:description" content="${description}" />
<meta property="og:description" content="${description}" />
`;

  if (image) {
    image = getCDNSrc(getWebpSrc(image));
    image = `${image.split('?')[0]}?width=768`;
    content += '<meta name="twitter:card" content="summary_large_image">\n';
    content += `<meta name="twitter:image" content="${image}">\n`;
    content += `<meta property="og:image" content="${image}">\n`;
  } else {
    content += '<meta name="twitter:card" content="summary">';
  }

  if (mutations && path) {
    mutations.forEach((mutation) => {
      const currentMutation = mutation.toLowerCase();
      const link = getSimpleUrlFromPath(
        getPathWithLocale(path, undefined, currentMutation)
      );
      let hreflang = currentMutation.split('-')[0];
      if (currentMutation === 'zh-cn') {
        hreflang = 'zh-hans';
      }
      if (currentMutation === 'zh-tw') {
        hreflang = 'zh-hant';
      }
      content += `<link rel="alternate" href="${link}" hreflang="${hreflang}" />`;
      if (currentMutation === defaultLocale.toLowerCase()) {
        content += `<link rel="alternate" href="${link}" hreflang="x-default" />`;
      }
    });
  }

  if (imagesToPreload.length > 0) {
    imagesToPreload.forEach((itp) => {
      itp = getCDNSrc(getWebpSrc(setQuality(itp)));
      content += `<link rel="preload" as="image" href="${itp}" imagesizes="100vw" imagesrcset="${getSrcSet(
        itp
      )}">`;
    });
  }

  return content;
}
