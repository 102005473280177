import {
  SliderInterface,
  SliderItemInterface,
} from '~/components/organisms/slider/types';
import { CztImage } from '../atoms/image';
import { CarouselInterface } from '~/components/organisms/carousel/types';
import { CarouselItemInterface } from '~/components/molecules/carouselItem/CarouselItem';
import {
  ContentBlockInterface,
  Position,
} from '~/components/organisms/contentBlock/types';
import {
  GridItem,
  GridListInterface,
} from '~/components/organisms/gridList/types';
import { GalleryInterface } from '~/components/organisms/gallery/types';
import {
  CarouselWidget,
  CarouselWidgetItem,
  ForeignRepsMapWidget,
  GalleryWidget,
  GalleryWidgetItem,
  GridWidget,
  GridWidgetItem,
  HtmlContentWidget,
  IframeWidget,
  MapWidget,
  NewsletterFormWidget,
  PanoramicPhotoWidget,
  SafeTravelsWidget,
  SliderWidget,
  SliderWidgetItem,
  SmartGuideWidget,
  TabbedContentWidget,
  TabbedContentWidgetItem,
  TradeOfferPage,
  TradeOffersWidget,
  VenueFinderWidget,
} from '~/app/core/apiClient/api';
import { ButtonInterface } from '~/components/atoms/button/Button';
import { VideoInterface } from '~/components/atoms/video/Video';
import { format, parse } from '~/utils/date-fns';
import toBoolean from '../toBoolean';
import { TabItemInterface } from '~/components/molecules/tabs/Tabs';
import { TabsWidgetInterface } from '~/components/templates/common/tabsWidget/types';
import { CztWidgets } from '~/utils/views/widgets';
import { RFC3339Format } from '~/utils/dateTime';
import { isRegionPage } from './typeguards';
import { isAspectRatio, ThemeRatios } from '~/utils/theme';
import { IframeBlockInterface } from '~/components/organisms/iframeBlock/types';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import WidgetStyleEnum = GridWidget.WidgetStyleEnum;
import ThemeStyleEnum = HtmlContentWidget.ThemeStyleEnum;
import {
  TradeOffersListInterface,
  TradeOffersListItem,
} from '~/components/templates/common/tradeOffersList/types';
import { Representation } from '~/components/templates/tradeOffers/TradeOffersListFilter';
import { RepresentationMapInterface } from '~/components/templates/common/representationMap/types';
import { supportedLocales } from '~/app/localization';
import { NewsletterFormInterface } from '~/components/templates/common/newsletterForm/types';
import { SafeTravelsListInterface } from '~/components/templates/safeTravels/types';
import { foreignRepresentationFlags } from '../foreignRepresentationFlags';
import { PanoramaInterface } from '~/components/organisms/panorama/types';
import { VenueFinderInterface } from '~/components/templates/common/venueFinder/types';
import { MapWidgetProps } from '~/components/templates/mapWidget/types';
import { isMapStyle, MapStyle } from '~/components/organisms/map/Map';
import {
  isPinCategory,
  PinCategory,
} from '~/components/templates/mapWidget/MapCategoryPin';
import isPositionEnum from '~/components/organisms/contentBlock/isPositionEnum';
import { SmartGuideInterface } from '~/components/organisms/smartGuide/types';
import { createPageTag, isPageTag } from '../pageTag/pageTag';
import { anchorPreposition } from '../anchor';

export function createGridList(widget: GridWidget): GridListInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map((item: GridWidgetItem) => createGridItem(item))
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  // Check if ratio-string from api exists in enum
  let imageRatio: ThemeRatios | undefined;
  if (
    widget.itemImageAspectRatio &&
    isAspectRatio(widget.itemImageAspectRatio)
  ) {
    imageRatio = (ThemeRatios as any)[widget.itemImageAspectRatio];
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.GRID,
    guid: widget.nodeGuid || 'unknown',
    title,
    items,
    imageAspectRatio: imageRatio,
    image: widget.backgroundImage
      ? createImage(
          widget.backgroundImage,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : undefined,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    maxItems: widget.maxItems ? widget.maxItems : 9,
    nextPageCount: widget.itemsCountNextPage,
    pageSize: widget.pageSize,
    taxonomyFilter: widget.taxonomyTags?.map(createPageTag).filter(isPageTag),
    taxonomies: widget.taxonomyTagTypesList,
    taxonomyLists: widget.taxonomyTagsByType
      ?.filter(
        (item) =>
          !!item.pageTagType?.trim() &&
          !!item.pageTags &&
          item.pageTags.length > 0
      )
      .map((item) => ({
        taxonomy: item.pageTagType || '',
        tags: item.pageTags?.map(createPageTag).filter(isPageTag) || [],
      })),
    showFlags: widget.showFlags || false,
    type: widget.widgetStyle || WidgetStyleEnum.Classic,
  };
}

export function createGridItem(widget: GridWidgetItem): GridItem {
  let date;
  let endDate;
  let flag;

  if (widget.url && widget.url.trim() !== '') {
    const foreignRep = Object.keys(foreignRepresentationFlags).find(
      (repPath) => {
        if (widget.url && widget.url.trim() !== '') {
          return widget.url.toLowerCase().indexOf(repPath.toLowerCase()) > -1;
        }
        return false;
      }
    );
    if (foreignRep) {
      flag = foreignRepresentationFlags.hasOwnProperty(foreignRep)
        ? foreignRepresentationFlags[
            foreignRep as keyof typeof foreignRepresentationFlags
          ]
        : undefined;
    }
  }

  if (widget.date) {
    const parsedDate = format(parse(widget.date), RFC3339Format);
    if (parsedDate) {
      date = parsedDate;
    }
  }

  if (widget.dateTo) {
    const parsedEndDate = format(parse(widget.dateTo), RFC3339Format);
    if (parsedEndDate) {
      endDate = parsedEndDate;
    }
  }

  return {
    date,
    endDate,
    description: widget.shortDesc || '',
    image: widget.image
      ? createImage(
          widget.image,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : createImage('', ''),
    imageFilter: widget.imageFilterType,
    link: widget.url || '',
    linkText: widget.urlText || '',
    perex: widget.perex || undefined,
    subtitle: widget.subtitle,
    title: widget.title || '',
    location: !isRegionPage(widget) ? widget.region || undefined : undefined,
    flag,
    forcedLocale: widget.cultureCode,
  };
}

export function createCarousel(widget: CarouselWidget): CarouselInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map((item: CarouselWidgetItem) => createCarouselItem(item))
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.CAROUSEL,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    title,
    items,
  };
}

export function createRepresentationMap(
  widget: ForeignRepsMapWidget
): RepresentationMapInterface {
  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.REPRESENTATION_MAP,
    continent: widget.continent || 'Europe',
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    items:
      widget.items && widget.items.length > 0
        ? widget.items.map((item) => {
            return {
              link: item.url || '',
              countryList: item.countryCodesList || [],
            };
          })
        : [],
    guid: widget.nodeGuid || 'unknown',
    title,
  };
}

export function createSlider(widget: SliderWidget): SliderInterface {
  const superTitle =
    !widget.hideTitle && widget.title ? widget.title : undefined;
  const slides = widget.items
    ? widget.items.map((slide: SliderWidgetItem) =>
        createSlide(slide, superTitle)
      )
    : [];

  let sliderRatio: ThemeRatios | undefined;
  if (widget.aspectRatio && isAspectRatio(widget.aspectRatio)) {
    sliderRatio = (ThemeRatios as any)[widget.aspectRatio];
  }
  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.SLIDER,
    items: slides,
    delay: widget.sliderDelaySec || 0,
    contentWidth: widget.useContentWidth || false,
    ratio: sliderRatio,
  };
}

export function createCarouselItem(
  data: CarouselWidgetItem
): CarouselItemInterface {
  // We need to validate and sanitize the date, because Swagger gen returns 'Date' type, but
  // the value is actually a string. So we first parse the date, check if it is valid
  // and then format it back to a default date time format
  let date: Date | string | undefined = data.date
    ? parse(data.date)
    : undefined;
  if (date) {
    date = isNaN(date.getTime()) ? undefined : format(date, RFC3339Format);
  }
  let endDate: Date | string | undefined = data.dateTo
    ? parse(data.dateTo)
    : undefined;
  if (endDate) {
    endDate = isNaN(endDate.getTime())
      ? undefined
      : format(endDate, RFC3339Format);
  }

  return {
    forcedLocale: data.cultureCode,
    imageFilter: data.imageFilterType,
    image: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    title: data.title ? data.title : '',
    url: data.url || '#',
    date,
    endDate,
    location: data.region ? data.region : undefined,
  };
}

export function createSlide(
  data: SliderWidgetItem,
  superTitle?: string
): SliderItemInterface {
  return {
    backgroundImage: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    content: data.description ? data.description : '',
    hideTitle: data.hideTitle,
    imageFilter: data.imageFilterType,
    largeHeadline: false,
    title: data.title ? data.title : '',
    button: createButton(data),
    showVideoControls: data.showVideoControls,
    sideImage: data.topLayerImage
      ? createImage(
          data.topLayerImage,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : undefined,
    sideContent: data.sideContent || undefined,
    superTitle,
    video: data.video ? createVideo(data.video) : undefined,
    imageSource: data.imageSource,
  };
}

export function createIframeBlock(data: IframeWidget): IframeBlockInterface {
  return {
    anchorId: data.anchorId && `${anchorPreposition}${data.anchorId}`,
    className: CztWidgets.IFRAME,
    isBottomSpacingCollapsed: data.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: data.isTopSpacingCollapsed || false,
    title: !data.hideTitle ? data.title || '' : '',
    url: data.url || '',
    height: data.height || undefined,
  };
}

export function createImage(src: string, alt: string): CztImage {
  const height = getUrlParameterByName('height', src);
  const width = getUrlParameterByName('width', src);
  return {
    src,
    alt,
    height: height && !isNaN(Number(height)) ? Number(height) : undefined,
    width: width && !isNaN(Number(width)) ? Number(width) : undefined,
    sizes: getUrlParameterByName('sizes', src) || undefined,
    srcset: getUrlParameterByName('srcset', src) || undefined,
    title: getUrlParameterByName('title', src) || alt,
  };
}

export function createButton(data: SliderWidgetItem): ButtonInterface {
  return {
    url: data.url || '',
    label: data.urlText || '',
  };
}

export function createVideo(src: string): VideoInterface | undefined {
  const videoId = getVideoId(src) || '';
  const autoPlay = toBoolean(getUrlParameterByName('autoplay', src));
  const disableKeyboard = toBoolean(getUrlParameterByName('disablekb', src));
  const loop = toBoolean(getUrlParameterByName('loop', src));
  const modestBranding = toBoolean(
    getUrlParameterByName('modestbranding', src)
  );
  const mute = toBoolean(getUrlParameterByName('mute', src));

  return {
    videoId,
    src,
    autoPlay: autoPlay === undefined ? true : autoPlay,
    disableKeyboard: disableKeyboard === undefined ? true : disableKeyboard,
    fullScreenButton:
      toBoolean(getUrlParameterByName('fullscreen', src)) || false,
    loop: loop === undefined ? true : loop,
    modestBranding: modestBranding === undefined ? true : modestBranding,
    showControls: toBoolean(getUrlParameterByName('controls', src)) || false,
    mute: mute === undefined ? true : mute,
  };
}

export function createGallery(widget: GalleryWidget): GalleryInterface {
  const items = widget.items
    ? widget.items.map((galleryItem: GalleryWidgetItem) => {
        return createGalleryItem(galleryItem);
      })
    : [];
  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.GALLERY,
    items,
    useContentWidth: widget.useContentWidth,
    isGrid: widget.style === 'grid',
    title:
      widget.style === 'grid' && !widget.hideTitle ? widget.title : undefined,
  };
}

export function createGalleryItem(
  data: GalleryWidgetItem
): GalleryItemInterface {
  return {
    image: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    imageFilter: data.imageFilterType,
    subtitle: '',
    title: data.title || '',
    imageSource: data.imageSource,
  };
}

export function createContentBlock(
  widget: HtmlContentWidget
): ContentBlockInterface {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.HTML,
    title,
    image: widget.image
      ? createImage(
          widget.image,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : undefined,
    imageUrl: widget.imageLinkUrl,
    content: `${widget.content}`,
    caption: widget.imageCaption ? widget.imageCaption.trim() : undefined,
    imageSource: widget.imageSource,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    position: isPositionEnum(widget.imagePosition)
      ? widget.imagePosition
      : Position.LEFT,
    themeStyle: widget.themeStyle || ThemeStyleEnum.None,
  };
}

export function createSmartGuideWidget(
  widget: SmartGuideWidget
): SmartGuideInterface {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    audioUrl: widget.audioUrl,
    className: CztWidgets.SMART_GUIDE,
    deepLinkUrl: widget.deepLinkUrl,
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createTabs(widget: TabbedContentWidget): TabsWidgetInterface {
  const tabs =
    widget.items?.map((item: TabbedContentWidgetItem) => {
      return createTabItem(item);
    }) || [];
  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.TABS,
    items: tabs,
    image: widget.image
      ? createImage(widget.image, widget.title || '')
      : undefined,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createTabItem(data: TabbedContentWidgetItem): TabItemInterface {
  return {
    content: data.content || '',
    label: data.title || '',
    link: data.url || undefined,
    buttonText: data.urlText || undefined,
    sideContent: data.sideContent,
  };
}

export function createTradeOffersList(
  widget: TradeOffersWidget
): TradeOffersListInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map(createTradeOfferItem)
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  const representations: Representation[] = widget.filteringOptions
    ? widget.filteringOptions.map((repre) => {
        return {
          id: repre.foreignRepGuid || 'unknown',
          name: repre.foreignRepName || '',
          languages: repre.languagesList
            ? repre.languagesList.map((language) => {
                const locale = supportedLocales.filter((supportedLocale) => {
                  return language.languageCode
                    ? supportedLocale.slug.indexOf(language.languageCode) > -1
                    : false;
                });
                let name = '';
                if (locale.length < 1 && language.languageName) {
                  name = language.languageName;
                } else {
                  name = locale[0].name;
                }
                return {
                  value: language.languageCode || '',
                  text: name,
                };
              })
            : [],
        };
      })
    : [];

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.TRADE_OFFFERS,
    items,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    guid: widget.nodeGuid || 'unknown',
    title,
    maxItems: widget.maxItems ? widget.maxItems : 9,
    nextPageCount: widget.itemsCountNextPage,
    pageSize: widget.pageSize,
    filterOptions: {
      representations,
    },
  };
}

export function createTradeOfferItem(
  data: TradeOfferPage
): TradeOffersListItem {
  const info: string[] = [];
  const language = supportedLocales
    .filter((locale) => locale.slug === data.cultureCode)
    .map((locale) => locale.name);
  if (
    data.foreignRepresentations &&
    data.foreignRepresentations.length > 0 &&
    data.foreignRepresentations[0].foreignRepName
  ) {
    info.push(data.foreignRepresentations[0].foreignRepName);
  }
  if (language.length > 0) {
    info.push(language.join(', '));
  }
  return {
    description: data.perex || data.content || '',
    id: data.nodeGuid || '',
    image: data.image
      ? createImage(data.image, data.title)
      : createImage('', ''),
    info: info.join(' | '),
    title: data.title,
    imageFilter: data.imageFilterType,
    link: data.nodeAliasPath || '',
    forcedLocale: data.cultureCode,
  };
}

export function createNewsletterForm(
  widget: NewsletterFormWidget
): NewsletterFormInterface {
  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.NEWSLETTER,
    type:
      widget.newsletterType === 'newsletter'
        ? undefined
        : widget.newsletterType,
    guid: widget.nodeGuid || 'unknown',
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createSafeTravels(
  widget: SafeTravelsWidget
): SafeTravelsListInterface {
  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.SAFE_TRAVELS,
    guid: widget.nodeGuid || 'unknown',
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createPanorama(
  widget: PanoramicPhotoWidget
): PanoramaInterface {
  let title = widget.title || '';

  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.PANORAMA,
    caption: widget.imageCaption,
    guid: widget.nodeGuid || 'unknown',
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    image: widget.image || '',
    imageSource: widget.imageSource,
    rotate: widget.automaticRotation || false,
    title,
  };
}

export function createMapWidget(widget: MapWidget): MapWidgetProps {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.MAP,
    id: widget.nodeGuid || '',
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    latitude:
      widget.mapCenterLatitude && !isNaN(widget.mapCenterLatitude)
        ? widget.mapCenterLatitude
        : 49.745049870549394,
    longitude:
      widget.mapCenterLongitude && !isNaN(widget.mapCenterLongitude)
        ? widget.mapCenterLongitude
        : 15.338053281429302,
    preselectedPoi:
      widget.items?.map((item) => ({
        id: item.nodeGuid || '',
        image: item.image || '',
        latitude: item.latitude || 0,
        longitude: item.longitude || 0,
        title: item.title,
        url: item.url || '',
        category:
          item.pageTypeName === 'event'
            ? PinCategory.EVENT
            : item.categories &&
              item.categories.length > 0 &&
              isPinCategory(item.categories[0].categoryGuid)
            ? item.categories[0].categoryGuid
            : PinCategory.DEFAULT,
      })) || [],
    defaultStyle: isMapStyle(widget.mapType) ? widget.mapType : MapStyle.BASIC,
    title,
    zoom: widget.mapZoom || 7,
  };
}

export function createVenueFinder(
  widget: VenueFinderWidget
): VenueFinderInterface {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    anchorId: widget.anchorId && `${anchorPreposition}${widget.anchorId}`,
    className: CztWidgets.VENUE_FINDER,
    guid: widget.nodeGuid || 'unknown',
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed,
  };
}

export function getUrlParameterByName(
  name: string,
  url: string
): string | null {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getVideoId(src: string): string | undefined {
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]+).*/;
  const matched = src.match(regex);
  if (!matched || matched.length < 1) {
    return undefined;
  }
  return matched[1];
}
